.btnNextLogin {
    cursor: pointer;
    position: absolute;
    top: calc(35vh);
    left: calc(100vw - 60px);

    &:hover {
        background-color: #202020;
    }
}

.container-carousel {
    padding-top: 20px;
    width: calc(100% - 200px);
    margin-left: 100px;

    .carousel-image {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

        img {
            height: calc(100vh - 120px);
        }
    }

    .containerLogin {
        padding-top: 60px;
    }
}

.dots {
    bottom: -15px !important;
}

body:has(.container-carousel) {
    overflow: hidden;
    background-color: #5d5d5d;
}

[class*='slick-prev'] {
    rotate: 180deg;
    width: 50px !important;
    height: 50px !important;
    inset-inline-start: -60px !important;
}

[class*='slick-next'] {
    width: 50px !important;
    height: 50px !important;
    inset-inline-end: -60px !important;
}
