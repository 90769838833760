.containerBar {
    height: calc(100vh - 74px);
    float: left;
    position: relative;
    width: 256px;
    padding-top: 10px;
    background: #0c0c0c;

    .header {
        padding: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: #fff;
        text-align: center;

        img {
            width: 150px;
        }
    }

    .menu {
        background-color: #0c0c0c;

        [class*="ant-menu-item-selected"] {
            background-color: #5d5d5d !important;
        }

        [class*="ant-menu-inline"] {
            background-color: #000000 !important;
        }
    }

    .content {
        background-color: #1b2a40;
    }
}

.containerBarCollapsed {
    width: 80px !important;
}

.parentMenuCollapsed,
.parentMenuCollapsed.ant-menu-item-selected {
    border: 0 !important;
    margin: 0 !important;
    background: #0c0c0c !important;
    color: #fff !important;
    cursor: default;

    &:hover {
        color: #fff;
    }
}
