.container {
    .groupField {
        display: flex;
        width: 100%;

        .inputGroup {
            input {
                margin-right: 10px;
                width: 150px !important;
                &.focus-visible {
                    border: red !important;
                }
            }
        }

        .inputGroupMask {
            input {
                margin-right: 10px;
                width: 175px !important;
                &.focus-visible {
                    border: red !important;
                }
            }
        }
    }

    .inputFormSm {
        margin-bottom: 17px;
    }

    .inputForm {
        margin-bottom: 17px;

        input {
            margin-right: 10px;
            width: 337px !important;
            &.focus-visible {
                border: red !important;
            }
        }
    }

    .activeItem {
        margin-top: 30px;
        margin-bottom: 10px;
        button {
            margin-top: -2px;
        }

        label {
            margin-left: 15px;
            font-size: 16px;
        }
    }

    .avatarInput {
        position: relative;
        top: -11px;
        left: 150px;
        background-image: url('../../../static/images/avatar-icon-70.jpg');
        height: 70px;
        width: 70px;
        margin-bottom: 20px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
        }
    }
}
