.container {
    .inputFormSm {
        margin-bottom: 17px;
    }

    .inputForm {
        margin-bottom: 17px;

        input {
            margin-right: 10px;
            width: 337px !important;
            &.focus-visible {
                border: red !important;
            }
        }
    }
}
