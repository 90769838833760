.containerWrapper {
    width: 400px;
    height: 570px;
    margin-left: 50%;
    position: relative;
    border-radius: 0.5rem;
    background: #000;
    display: flex;
    left: -200px;
    top: -30px;

    .groupField {
        display: flex;
        width: 100%;

        [class$="inputGroup"] {
            color: #fff !important;
            input {
                margin-right: 10px;
                width: 107px !important;
                &.focus-visible {
                    border: red !important;
                }
            }
        }
    }

    .loginWrapper {
        align-items: center;
        width: 50%;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;

        img {
            position: relative;
            left: 130px;
            top: 20px;
        }

        .contentWrapper {
            position: relative;
            top: 80px;
            margin-left: 60px;
            width: 300px;
            color: #fff;
            [class$="inputLogin"] {
                padding-bottom: 0px;
                color: #fff;
                font-size: 18px;
                input {
                    width: 250px;
                    &.focus-visible {
                        border: red !important;
                    }
                }
            }

            button {
                font-family: "Poppins", sans-serif;
                width: 280px;
                height: 35px;
                margin-top: 20px;
            }

            .labelForgotPassword {
                cursor: pointer;
                color: #9ca8b3 !important;
                position: relative;
                left: 87px;
                top: 26px;
                font-size: 12px;
            }

            .text {
                text-align: center;
                margin-top: -40px;
                margin-left: -245px;
            }

            .textRecover {
                text-align: center;
                margin-top: -40px;
                margin-left: -135px;
            }
        }
    }
}
