.containerWrapper {
    width: 400px;
    height: 620px;
    margin-left: 50%;
    position: relative;
    border-radius: 0.5rem;
    background: #000;
    display: flex;
    left: -200px;
    top: -30px;

    .groupField {
        display: flex;
        width: 100%;

        [class$='inputGroup'] {
            color: #fff !important;
            input {
                margin-right: 10px;
                width: 107px !important;
                &.focus-visible {
                    border: red !important;
                }
            }
        }
    }

    .registerWrapper {
        width: 50%;
        background-size: cover;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border: none;
        color: #fff;

        img {
            position: relative;
            left: 130px;
            top: 20px;
        }

        .contentWrapper {
            padding-top: 0px;
            margin-left: 60px;

            .text {
                text-align: center;
                margin-top: 30px;
                margin-left: -30px;
            }

            [class$='inputLogin'] {
                padding-bottom: 0px;
                color: #fff;
                input {
                    width: 250px;
                    &.focus-visible {
                        border: red !important;
                    }
                }
            }

            [class$='inputLoginMask'] {
                padding-bottom: 0px;
                color: #fff;
                input {
                    width: 275px;
                    &.focus-visible {
                        border: red !important;
                    }
                }
            }

            button {
                font-family: 'Poppins', sans-serif;
                width: 280px;
                height: 35px;
                margin-top: 20px;
            }
        }
    }
}
