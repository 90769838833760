body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast--success {
  background: #40a943 !important;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark.ant-menu-submenu>.ant-menu,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark>.ant-menu.ant-menu-submenu>.ant-menu {
  background-color: #0c0c0c;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #5d5d5d;
}

.ant-modal-body:has([class*='Register_containerWrapper']) {
  height: 20px !important;
}