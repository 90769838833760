.container {
    .toolBar {
        margin-bottom: 15px;
        display: flex;
    }

    .magazine-pages {
        padding-top: 20px;
        display: flex;
        flex-flow: row wrap;
        img {
            padding: 5px;
            border: 1px solid #cdcdcd;
            border-radius: 5px;
            margin-right: 5px;
            margin-bottom: 10px;
        }

        .button-delete {
            position: relative;
            left: 50px;
            top: -10px;
            color: red;
        }
    }

    .upload {
        margin-top: 25px;
        font-weight: 500;

        .selectFile {
            padding-top: 7px !important;
            height: 20px;
            width: 400px;
            font-size: 13px;
            border: 1px solid #ced4da;
            padding: 5px;
            text-align: center;
            cursor: pointer;
            border-radius: 4px;
            color: #fff;
            background-color: #40779e;
        }

        .container {
            display: flex;

            .fileSelected {
                display: flex;
                height: 34px;
                margin-right: 10px;

                .fileName {
                    padding-top: 7px !important;
                    width: 370px;
                    font-size: 13px;
                    border-top: 1px solid #ced4da;
                    border-bottom: 1px solid #ced4da;
                    border-left: 1px solid #ced4da;
                    padding: 5px;
                    text-align: center;
                    color: #fff;
                    background-color: green;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                .clearFile {
                    padding-top: 7px !important;
                    width: 30px;
                    font-size: 13px;
                    border-top: 1px solid #ced4da;
                    border-bottom: 1px solid #ced4da;
                    border-right: 1px solid #ced4da;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    background: #c14444;
                    color: #fff;
                    text-align: center;
                    padding-top: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}
