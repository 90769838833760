.container {
    height: 64px;
    background-color: #000;
    box-shadow: 1px 0 5px #00000033;
    display: flex;

    .contentLogo {
        width: 270px;
        text-align: center;
        padding-top: 7px;
    }

    .botoesLogin {
        position: relative;
        left: 100vw;
        margin-left: -450px;
        top: 15px;
    }

    .nomeUsuario {
        position: relative;
        left: 100vw;
        margin-left: -700px;
        top: 20px;
        width: 380px;
        text-align: right;
        color: #fff;

        label {
            position: relative;
            top: -30px;
            margin-right: 20px;
        }

        img {
            border-radius: 50px;
            position: relative;
            width: 50px;
            height: 50px;
            top: -13px;
            background-image: url("../../../static/images/avatar-icon-50.jpg");
        }
    }

    .contentCollapse {
        svg {
            cursor: pointer;
            font-size: 20px;
            color: #fff;
            top: 21px;
            position: relative;
        }
    }
}

[class*="ant-modal-content"]:has([class*="Login_containerWrapper"]) {
    background: none;
    box-shadow: none;
    height: 100px;
}
